import React from 'react'
import styled from 'styled-components'
import { TbSofa } from "react-icons/tb";
import { TfiRulerPencil } from "react-icons/tfi";
import { IoExtensionPuzzleOutline } from "react-icons/io5";

import { Flex, Space, Subtitle } from '../config/universalStyles'
import { THEME_COLORS } from '../config/theme'
import { ServiceCard } from '../components/ServiceCard'
import { services_data } from '../data/services_data';
import { useMediaQuery } from '../customHooks/useMediaQuery';
import { device } from '../util/devices';

const Wrapper = styled.div`
`

const SectionOne = styled.div`
  background-image: url("https://prague.foxthemes.me/wp-content/uploads/2016/05/9b437744429717.5812370040b1c.jpg");
  height: 100vh;
  width: 100%;
`

const SectionOneContent = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  background-color: rgba(0,0,0,0.3);
`

const Title = styled.h3`
  color: white;
  font-weight: 900;
  font-size: ${props => props.isMobile ? "1.8em" : "3.2em"};
  letter-spacing: 1px;
`

const SectionTwo = styled.div`
  
  display: flex;
  justify-content: space-between;
  width: 75%;
  max-width: 1200px;
  margin: 10em auto;
`

const SectionTitle = styled.h3`
  font-size: 2em;
  font-weight: 800;
`

const SectionSubTitle = styled.p`
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 5px;
  margin-bottom: 1em;
  text-transform: uppercase;
  color: rgba(0,0,0,0.8);
`

const Desc = styled.p`
  line-height: 1.7em;
`

const SectionImageWrapper = styled.div`
  width: 300px;
  height: 300px;
`

const SectionTwoImage = styled.img`
  background-image: url(https://prague.foxthemes.me/wp-content/uploads/2017/01/stock-photo-171784595.jpg);
  width: inherit;
  height: inherit;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
`

const SectionThree = styled.div`
  background-image: url(https://prague.foxthemes.me/wp-content/uploads/2017/02/a6ec5612273735.56265d52bc52e-1.jpg);
  height: 50vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`

const SectionThreeContent = styled.div`
  background-color: rgba(0,0,0,0.3);
  height: inherit;
`

const SectionContentWrap = styled.div`
  padding-top: 5em;
  width: 75%;
  max-width: 1200px;
  margin: auto;
`

const ServiceCardWrapper = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: 100%; */
  max-width: 1200px;
  margin: auto;
  margin-top: -12em;
  position: relative;
`

export const Services = () => {
  const isMobile = useMediaQuery(device.mobile);
  const isTabletL = useMediaQuery(device.tabletL);
  return (
    <Wrapper>
      <SectionOne>
        <SectionOneContent>
          <Subtitle>We propose</Subtitle>
          <Title>Best Services</Title>
        </SectionOneContent>
      </SectionOne>

      <SectionTwo>
        <SectionImageWrapper hidden={isMobile}>
          <SectionTwoImage />
        </SectionImageWrapper>
        <div style={{ width: isMobile ? "100%" : "60%" }}>
          <SectionSubTitle>OUR BASICS</SectionSubTitle>
          <Space size="1em" />
          <SectionTitle>Transform knowledge <br />into creative potential.</SectionTitle>
          <Space size="2em" />
          <Desc>
            The company principle of Prague is the collective conception. From the very beginning, the practice has believed in the virtues of exchange, crossing ideas, common effort, shared knowledge and enthusiasm. Prague studio believes in a socially engaged architecture. This approach is nourished by the numerous worldwide achievements made over the last forty years, and by a constant attention to the quality of public spaces and new uses.
          </Desc>
        </div>
      </SectionTwo>

      <SectionThree>
        <SectionThreeContent>
          <SectionContentWrap>
            <Subtitle>Services</Subtitle>
            <Title isMobile={isMobile}>This is what we do.</Title>
          </SectionContentWrap>

        </SectionThreeContent>
      </SectionThree>

      <ServiceCardWrapper>

        {services_data.map((service, index) => (
          <ServiceCard
            isMobile={isMobile}
            key={index}
            title={service.title}
            desc={service.shortDesc}
          >
            <TfiRulerPencil style={{ fontSize: '40px' }} />
          </ServiceCard>
        ))}

      </ServiceCardWrapper>

    </Wrapper>
  )
}
