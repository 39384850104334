import { TbSofa } from "react-icons/tb";
import { TfiRulerPencil } from "react-icons/tfi";
import { IoExtensionPuzzleOutline } from "react-icons/io5";

export const services_data = [
  {
    title: "Planning",
    shortDesc: "Our master plans provide a comprehensive look at where an organization is today.",
    // longDesc: "",
    link: "/planning",
    icon: TbSofa,
    coverImgUrl: ""
  },
  {
    title: "Interior",
    shortDesc: "You may engage your architect to provide an interior design service, advising on loose furniture.",
    // longDesc: "",
    link: "/interior",
    icon: TfiRulerPencil,
    coverImgUrl: ""
  },
  {
    title: "Exterior",
    shortDesc: "Working together with your architect, you will share your project needs, dreams and goals.",
    // longDesc: "",
    link: "/planning",
    icon: IoExtensionPuzzleOutline,
    coverImgUrl: ""
  },
  {
    title: "Renovation",
    shortDesc: "Describes a particular treatment approach and philosophy within the field of architectural conservation",
    // longDesc: "",
    link: "/renovation",
    icon: TbSofa,
    coverImgUrl: ""
  },
  {
    title: "Building",
    shortDesc: "When the design concepts have been decided, the architect prepares working drawings and specifications.",
    // longDesc: "",
    link: "/building",
    icon: TfiRulerPencil,
    coverImgUrl: ""
  },
  {
    title: "Consultation",
    shortDesc: "Our architect will consult with you and advise you during construction process.",
    // longDesc: "",
    link: "/consultation",
    icon: IoExtensionPuzzleOutline,
    coverImgUrl: ""
  },

]