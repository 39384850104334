import React from 'react'
import { projects } from '../data/projects'
import { ProjectDetails } from '../components/ProjectDetails'
import styled from 'styled-components'
import { Flex } from '../config/universalStyles'
import { useMediaQuery } from '../customHooks/useMediaQuery'
import { device } from '../util/devices'


const Wrapper = styled.div`
  margin: auto;
  padding-top: 6.5em;
  width: 80%;

  /* background-color: rgb(247 224 106 / 3%); */
`

export const Projects = () => {
  const isMobile = useMediaQuery(device.mobile);
  const isTabletL = useMediaQuery(device.tabletL);
  return (
    <Wrapper>
      {projects.map((project, index) => (
        <ProjectDetails
          key={index}
          isMobile={isMobile}
          title={project.title}
          year={project.year}
          imgUrl={project.imgUrl}
          desc={project.desc}
          reverse={index % 2}
          link={project.link}
        />
      ))}
    </Wrapper>
  )
}
