import React from 'react'
import styled from 'styled-components'
import { AppBtnTwo, Space, Title } from '../config/universalStyles'



const ServiceCardWrap = styled.div`
  box-shadow: 0 30px 50px rgb(0 0 0 / 20%);
  padding: 4em;
  width: ${props => props.isMobile ? "100%" : "32%"};
  /* width: 32%; */
  text-align: center;
  background-color: white;
  margin-bottom: 3em;
  align-self: stretch;
  transition: 0.5s ease-in-out all;

  &:hover{
    scale: 1.01;

  }
`

const CardTitle = styled(Title)`
  color: black;
  font-size: 35px;
  letter-spacing: 2.5px;
  font-weight: 700;
  text-shadow: -1px 0 1px black, 0 1px 1px black, 1px 0 1px black, 0 -1px 1px black;
  text-transform: capitalize;
`
const CardSubtitle = styled.p`
  line-height: 2em;
  letter-spacing: 1px;
`

export const ServiceCard = ({ children, title, desc, link, ...props }) => {
  return (
    <ServiceCardWrap isMobile={props.isMobile}>
      {children}
      <Space size="20px" />
      <CardTitle>{title}</CardTitle>
      <Space size="20px" />
      <CardSubtitle>
        {desc}
      </CardSubtitle>
      <Space size="40px" />
      <AppBtnTwo color="black">Read More</AppBtnTwo>

    </ServiceCardWrap>

  )
}
