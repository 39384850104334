import { projectPageDetails } from "../data/projectPageDetails";

export const getPageName = (str) => {
  const name = str.split("-")[0]
  return name;
}

export const getPageData = (str) => {

  const data = projectPageDetails[str]
  return data;

}