export const marqueeProjects = [
  {
    title: "Poolscape Villas",
    marqueeTitle: "Upcoming Project:",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg",
    year: 2010,
    link: "poolscape-villa"
  },
  {
    title: "European Lard Station",
    marqueeTitle: "Recently Completed:",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/c5854644221919.580bb620ecb87.jpg",
    year: 2019,
    link: "european-lard-station"
  },
  {
    title: "Poolscape Villa",
    marqueeTitle: "Upcoming Estate Project:",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg",
    year: 2010,
    link: "poolscape-villa"
  },
  {
    title: "European Lard Station",
    marqueeTitle: "Ongoing Estate Project:",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/c5854644221919.580bb620ecb87.jpg",
    year: 2019,
    link: "european-lard-station"
  },
  {
    title: "Poolscape Villa",
    marqueeTitle: "Upcoming Project:",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg",
    year: 2010,
    link: "poolscape-villa"
  },

]