import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../config/theme'
import { AppBtn, Space, Flex, AppBtnTwo, Subtitle, AppSubtitle } from '../config/universalStyles'
import experienceImg from '../asset/img/experience.jpeg'
import { SectionHead } from '../components/SectionHead'
import { TbSofa } from "react-icons/tb";
import { TfiRulerPencil } from "react-icons/tfi";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { ServiceCard } from '../components/ServiceCard'
import { ProjectDetails } from '../components/ProjectDetails'
import { MarqueeComponent } from '../components/MarqueeComponent'
import { useMediaQuery } from '../customHooks/useMediaQuery'
import { device } from '../util/devices'

const BORDER_CONSTANT = '0.5px solid black'

const Wrapper = styled.div``

const SectionOne = styled.section`
  background-image: url("https://prague.foxthemes.me/wp-content/uploads/2016/05/9b437744429717.5812370040b1c.jpg");
  /* position: absolute; */
  height: 100vh;
  width: 100%;
  background-attachment: fixed;
`

const SectionOneContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  padding: 20px;
  background-color: rgba(0,0,0,0.3);
`

const Title = styled.h3`
  color: ${THEME_COLORS.WHITE};
  font-weight: 900;
  font-size: 3em;
  text-shadow: -0.5px 0 0.4px black, 0 0.4px 0.4px black, 0.4px 0 0.4px black, 0 -0.4px 0.4px black;
  text-align: ${props => props.isTabletL ? "center" : "left"};
`



const ExperienceSection = styled(Flex)`
  margin: auto;
  margin-top: 2em;
  width: 80%;
`

const ExpLeftDiv = styled.div`
  height: 50vh;
  width: 30%;
  border-right: 1px solid black;
  padding: 3.5em 5em;
  padding-left: 0;
`

const ExpSubtitle = styled.h5`
  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.4em;
  margin-top: 30px;
  position: relative;

  &::before{
    content: '';
    width: 60px;
    border: 2px solid black;
    position: absolute;
    top: -30px;
  }
`

const ExpTitle = styled.h3`
  font-weight: 500;
  font-size: 7em;
  margin-top: 1.6em;
`

const ExpContentWrap = styled.div`
  /* flex: 2; */
  width: 100%;
  padding: ${props => props.isMobile ? "10px" : "0 5.8em"};
  padding-right: 0;

`

const ExpContent = styled.p`
  font-weight: 500;
  font-size: ${props => props.isMobile ? "20px" : "34px"};
  letter-spacing: 1px;
  line-height: 1.5em;
`

const ProjectWrap = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 3em;
`
const ServiceSection = styled.div`
  /* background-color: aliceblue; */
  padding: 3em 0;
`
const ServiceWrap = styled.div`
  width: 80%;
  margin: auto;
`
const ServiceCardWrapper = styled(Flex)`
  margin: 4em auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

export const Home = () => {
  const isMobile = useMediaQuery(device.mobile);
  const isTabletL = useMediaQuery(device.tabletL);

  return (
    <Wrapper>
      <SectionOne>
        <SectionOneContent isMobile={isMobile}>
          <Subtitle center hidden={isMobile}>DESIGN | BUILD | TRANSFORM | HANDOVER</Subtitle>
          {/* <Title>Architecture is a visual Art, <br />... the buildings speak for themselves</Title> */}
          {/* <Title>Let's Make Your Dream Home 3D</Title> */}
          <Title center={isTabletL}>Let's Make Your Dream Home A Reality</Title>
          <Space size="20px" />
          <AppBtn transparent>Start Project</AppBtn>
        </SectionOneContent>
      </SectionOne>

      {/* Marquee */}
      <MarqueeComponent />

      {/* Experience Section */}
      <ExperienceSection isMobile={isMobile}>
        {/* <ExpLeftDiv isTabletL={isTabletL} hidden={isMobile}> */}
        <ExpLeftDiv hidden={isMobile || isTabletL}>
          <ExpSubtitle>
            Years <br />of Experience
          </ExpSubtitle>
          <ExpTitle>
            30
          </ExpTitle>
        </ExpLeftDiv>
        <ExpContentWrap isMobile={isMobile}>
          <ExpContent isMobile={isMobile}>
            We are a team of design-led architects & designers that believe in the value of well-considered design and how it can positively impact lives, communities and the broader environment
          </ExpContent>
          <Space size="5em" />
          <AppBtnTwo color="black">More About Us</AppBtnTwo>
        </ExpContentWrap>
      </ExperienceSection>

      <ProjectWrap>
        <SectionHead
          subTitle="Last project"
          title="Made With Passion"
          linkText="See All Projects"
        />

        <Space size="3.5em" />

        {/* 1st Project */}
        <ProjectDetails
          isMobile={isMobile}
          reverse={false}
          imgUrl="https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg"
          year={2010}
          title="Poolscape Villa"
          desc="The Seascape Villas project constitutes one of the first urban interventions in this very unique context, a landscape dominated by mountains and sea."
        />

        {/* 2nd Project */}
        <ProjectDetails
          isMobile={isMobile}
          reverse={true}
          imgUrl="https://prague.foxthemes.me/wp-content/uploads/2017/01/c5854644221919.580bb620ecb87.jpg"
          year={2010}
          title="European Lard Station"
          desc="Hemicycle and offices for the Headquarters of the European Parliament including: a 750-seat hemicycle, 1133 offices for members of Parliament, 18 commission halls and catering centre and service areas."
        />

      </ProjectWrap>

      {/* Services */}
      <ServiceSection>
        <ServiceWrap>
          <SectionHead

            subTitle="services"
            title="This is what we do."
            linkText="See all services"
          />
          <ServiceCardWrapper>
            <ServiceCard
              isMobile={isMobile}
              title="planning"
              desc="Our master plans provide a comprehensive look at where an organization is today."
            >
              <TfiRulerPencil style={{ fontSize: '40px' }} />
            </ServiceCard>

            <ServiceCard
              isMobile={isMobile}
              title="Building"
              desc="When the design concepts have been decided, we prepare working drawings and specifications and move to site."
            >
              <IoExtensionPuzzleOutline style={{ fontSize: '40px' }} />
            </ServiceCard>

            <ServiceCard
              isMobile={isMobile}
              title="Interior"
              desc="Working together with your architect, you will share your project needs, dreams and goals."
            >
              <TbSofa style={{ fontSize: '40px' }} />
            </ServiceCard>

          </ServiceCardWrapper>
        </ServiceWrap>
      </ServiceSection>

    </Wrapper >
  )
}
