import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import { Carousel } from 'react-responsive-carousel';

import { getPageData, getPageName } from '../util/utilFunctions';
import styled from 'styled-components';
import { Description, Space, SpaceBetween } from '../config/universalStyles';
import { SectionHead } from '../components/SectionHead';

import 'react-before-after-slider-component/dist/build.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../asset/styles/styles.css"
import { useMediaQuery } from '../customHooks/useMediaQuery';
import { device } from '../util/devices';


const ContentWrapper = styled(SpaceBetween)`
  align-items: flex-start;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  flex-direction: ${props => props.isMobile ? "column" : "row"};
`

const TextSection = styled.div`
  flex: 1;
`

const ImageSection = styled.div`
  flex: 1.5;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  align-self: flex-end;
  display: block;
  margin-bottom: 35px;
`

const Desc = styled.p`
  line-height: 33px;
  margin-bottom: 30px;
  opacity: 0.8;
`


export const Project = () => {
  const { id } = useParams();
  const pageName = getPageName(id)

  const [pageData, setPageData] = useState(null);

  const isMobile = useMediaQuery(device.mobile);
  const isTabletL = useMediaQuery(device.tabletL);

  useEffect(() => {
    const data = getPageData(pageName)
    setPageData(data)
  }, [id])

  return (
    <div>
      {pageData && (
        <>
          <ReactBeforeSliderComponent
            firstImage={pageData.firstImage}
            secondImage={pageData.secondImage}
            className='slider_comp'
          />
          <Space size="4em" />

          <ContentWrapper
            isMobile={isMobile}
          >

            <TextSection>
              <SectionHead
                subTitle={pageData.type}
                title={pageData.title}
              />

              <Desc>
                {pageData.longDesc[0]}
              </Desc>
              <Space size="2em" />
              <Desc>
                {pageData.longDesc[1]}
              </Desc>

            </TextSection>
            <Space size="2em" />
            <ImageSection>
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
              >
                {pageData.images.map((imageUrl, index) => (
                  /* <div key={index} > */
                  <Image src={imageUrl} />
                  /* </div> */
                ))}
              </Carousel>
            </ImageSection>


          </ContentWrapper>
        </>
      )
      }
    </div >
  )
}
