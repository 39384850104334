export const projects = [
  {
    title: "Poolscape Villa",
    desc: "The Seascape Villas project constitutes one of the first urban interventions in this very unique context, a landscape dominated by mountains and sea.",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg",
    year: 2010,
    link: "poolscape-villa"
  },
  {
    title: "European Lard Station",
    desc: "Hemicycle and offices for the Headquarters of the European Parliament including: a 750-seat hemicycle, 1133 offices for members of Parliament, 18 commission halls and catering centre and service areas.",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/c5854644221919.580bb620ecb87.jpg",
    year: 2019,
    link: "european-lard-station"
  },
  {
    title: "Poolscape Villa",
    desc: "The Seascape Villas project constitutes one of the first urban interventions in this very unique context, a landscape dominated by mountains and sea.",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/02/a6ec5612273735.56265d52bc52e-1.jpg",
    year: 2010,
    link: "poolscape-villa"
  },
  {
    title: "European Lard Station",
    desc: "Hemicycle and offices for the Headquarters of the European Parliament including: a 750-seat hemicycle, 1133 offices for members of Parliament, 18 commission halls and catering centre and service areas.",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/c5854644221919.580bb620ecb87.jpg",
    year: 2019,
    link: "european-lard-station"
  },
  {
    title: "Poolscape Villa",
    desc: "The Seascape Villas project constitutes one of the first urban interventions in this very unique context, a landscape dominated by mountains and sea.",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg",
    year: 2010,
    link: "poolscape-villa"
  },
  {
    title: "European Lard Station",
    desc: "Hemicycle and offices for the Headquarters of the European Parliament including: a 750-seat hemicycle, 1133 offices for members of Parliament, 18 commission halls and catering centre and service areas.",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/02/a6ec5612273735.56265d52bc52e-1.jpg",
    year: 2019,
    link: "european-lard-station"
  },
  {
    title: "Poolscape Villa",
    desc: "The Seascape Villas project constitutes one of the first urban interventions in this very unique context, a landscape dominated by mountains and sea.",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg",
    year: 2010,
    link: "poolscape-villa"
  },
  {
    title: "European Lard Station",
    desc: "Hemicycle and offices for the Headquarters of the European Parliament including: a 750-seat hemicycle, 1133 offices for members of Parliament, 18 commission halls and catering centre and service areas.",
    imgUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/02/a6ec5612273735.56265d52bc52e-1.jpg",
    year: 2019,
    link: "european-lard-station"
  },

]