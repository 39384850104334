import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../config/theme'
import { AppSubtitle, AppTitle, Space, SpaceBetween, Subtitle, Title } from '../config/universalStyles'

const Wrapper = styled.div`
  /* width: 80%; */
  margin: auto;
  margin-bottom: 2em;
`

const SectionTitle = styled(Title)`
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 55px;
  margin-bottom: 10px;
`

const ProjectLink = styled(AppSubtitle)`
  letter-spacing: 2px;
  cursor: pointer;
  padding: 2px;
  transition: 0.5s all ease;
  border-bottom: 1px solid #000;

  &:hover{
    letter-spacing: 3px;
    margin-right: 20px;
    font-weight: bold;
  }
`



export const SectionHead = ({ title, subTitle, linkText, link, ...props }) => {
  return (
    <Wrapper>
      <SpaceBetween>
        <AppSubtitle color={THEME_COLORS.FONT_COLOR}>{subTitle}</AppSubtitle>
        <ProjectLink color={THEME_COLORS.FONT_COLOR} link>{linkText}</ProjectLink>
      </SpaceBetween>
      <AppTitle color="black">{title}</AppTitle>
    </Wrapper>
  )
}
