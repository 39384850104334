const size = {
  mobileS: '120px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '500px',
  mobileXXL: '600px',
  tablet: '750px',
  tabletL: '900px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  // mobile: `(max-width: ${size.mobileXL})`,
  mobile: `(min-width: ${size.mobileS}) and ((max-width: ${size.mobileXXL}))`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tablet}) and ((max-width: ${size.tabletL}))`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};