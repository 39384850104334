import { useState } from 'react';
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { navMenu } from '../data/navbarData'
import { Flex, SpaceBetween } from '../config/universalStyles'
import { useMediaQuery } from '../customHooks/useMediaQuery';
import { device } from '../util/devices';
import { RxHamburgerMenu } from "react-icons/rx";
import { GrClose } from "react-icons/gr";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  padding: 30px 20px;
  background-color: ${props => props.isMobile ? "transparent" : "rgba(0,0,0,0.5)"};
  z-index: 200;
  width: 100%;
`

const LogoDiv = styled.div`
`

const MenuList = styled(Flex)`
  /* gap: 20px; */
  /* margin-left: 30px; */
`

const MenuItem = styled.div`
  /* background-color: #fff; */
  padding: 10px;
  margin-right: 25px;
`

const Title = styled.a`
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  transition: 0.2s all ease;
  font-weight: 300;
  opacity: 0.8;

  &:hover{
    /* color: rgba(255,255,255,1); */
    /* font-weight: 400; */
    opacity: 1;
  }
`

const MobileMenuWrap = styled.div`
  background-color: ${props => props.active ? "white" : "black"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  position: inherit;
  border: 1px solid black;
`

const MenuIcon = styled(RxHamburgerMenu)`
  font-size: 25px;
  color: white;
`

const CloseIcon = styled(GrClose)`
  font-size: 25px;
  color: black;
`

const MobileMenuList = styled.div`
  background-color: rgba(0,0,0,0.5);
  padding: 20px;
  width: 50%;
  margin-top: 3em;
`

const MobileMenuItem = styled.div`
  margin-bottom: 1em;
  color: white;
  font-weight: bold;
`



export const Navbar = ({ }) => {
  const [active, setActive] = useState(false)
  const isMobile = useMediaQuery(device.mobile);
  // const isTablet = useMediaQuery(device.tablet);

  const handleMenuClick = () => {
    setActive(!active)
  }

  return (
    <Wrapper isMobile={isMobile}>
      {isMobile ?
        (<>
          <MobileMenuWrap onClick={handleMenuClick} active={active}>
            {active ? <CloseIcon /> : <MenuIcon />}
          </MobileMenuWrap>

          {active && (
            <MobileMenuList>
              {navMenu.map((data, index) => (
                <MobileMenuItem key={index} onClick={handleMenuClick}>
                  <Link to={data.link}> <Title>{data.title}</Title></Link>
                </MobileMenuItem>
              ))}
            </MobileMenuList>
          )}

        </>

        )
        :
        <SpaceBetween>
          <LogoDiv></LogoDiv>
          <MenuList>
            {navMenu.map((data, index) => (
              <MenuItem key={index}>
                <Link to={data.link}> <Title>{data.title}</Title></Link>
              </MenuItem>
            ))}
          </MenuList>
        </SpaceBetween>
      }

    </Wrapper>
  )
}
