export const projectPageDetails = {
  poolscape: {
    title: "Poolscape Villa",
    desc: "The Seascape Villas project constitutes one of the first urban interventions in this very unique context, a landscape dominated by mountains and sea.",
    longDesc: [
      "The Round Mountain House references local precedents by combining modernist ideals with vernacular strategies to integrate seamlessly into the Ozark Mountain landscape.",

      "The form of the house is treated as two pieces that separate public and private spaces. The “main frame” consists of public spaces: the carport, outdoor breezeway, the guest loft, and main living area; the “lean-to” or “saddle bag” contains private spaces: the laundry, bathrooms, closets, and bedrooms."
    ],
    firstImage: {
      imageUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg",
    },
    secondImage: {
      imageUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/c5854644221919.580bb620ecb87.jpg",
    },
    year: 2010,
    link: "poolscape-villa",
    location: "Guangzhou, China",
    Director: "Harmony Soulth",
    images: ["https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg", "https://prague.foxthemes.me/wp-content/uploads/2017/08/upload-2.jpg", "https://prague.foxthemes.me/wp-content/uploads/2017/08/upload-1.jpg", "https://prague.foxthemes.me/wp-content/uploads/2017/08/uload-4.jpg"],
    type: "4 Bedrooms Detached"
  },
  european: {
    title: "European Lard Station",
    desc: "Hemicycle and offices for the Headquarters of the European Parliament including: a 750-seat hemicycle, 1133 offices for members of Parliament, 18 commission halls and catering centre and service areas.",
    longDesc: [
      "The Round Mountain House references local precedents by combining modernist ideals with vernacular strategies to integrate seamlessly into the Ozark Mountain landscape.",

      "The form of the house is treated as two pieces that separate public and private spaces. The “main frame” consists of public spaces: the carport, outdoor breezeway, the guest loft, and main living area; the “lean-to” or “saddle bag” contains private spaces: the laundry, bathrooms, closets, and bedrooms."
    ],
    firstImage: {
      imageUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/c5854644221919.580bb620ecb87.jpg",
    },
    secondImage: {
      imageUrl: "https://prague.foxthemes.me/wp-content/uploads/2017/01/51f1b143659493.57f77d5c9619a.jpg",
    },
    year: 2019,
    link: "european-lard-station",
    location: "Guangzhou, China",
    Director: "Harmony Soulth",
    images: ["https://prague.foxthemes.me/wp-content/uploads/2017/08/upload-2.jpg", "https://prague.foxthemes.me/wp-content/uploads/2017/08/upload-1.jpg", "https://prague.foxthemes.me/wp-content/uploads/2017/08/uload-4.jpg"],
    type: "4 Bedrooms Detached"
  },
}