import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../config/theme'
import { Flex, Space, SpaceBetween, Subtitle } from '../config/universalStyles'
import { GrLinkedinOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { FiFacebook } from "react-icons/fi";
import { SiTiktok } from "react-icons/si";


const Wrapper = styled.div`
  height: 60vh;
  background-image: url("https://prague.foxthemes.me/wp-content/uploads/2016/05/9b437744429717.5812370040b1c.jpg");
  color: ${THEME_COLORS.WHITE};
`

const FooterContent = styled.div`
  background-color: rgba(0,0,0,0.8);
  height: inherit;
  width: inherit;
`

const FooterBody = styled.div`
  width: 80%;
  margin: auto;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const FooterHeaderSection = styled(SpaceBetween)`
  gap: 4em;
  padding-bottom: 3em;
  border-bottom: 1px solid rgba(255,255,255,0.8);
  opacity: 0.8;
`
const LogoDiv = styled.div`
  width: 40%;
`
const LogoText = styled.h1`
  margin-bottom: 1em;
  text-shadow: -1px 0 1px black, 0 1px 1px black, 1px 0 1px black, 0 -1px 1px black;
  font-weight: 700;
`

const GetInTouch = styled(Subtitle)`
  margin: 0;
  text-align: right;
  margin-bottom: 1.5em;
`
const Desc = styled.p`
  font-size: 14px;
  line-height: 2em;
  text-align: ${props => props.align};
  
`

const Icons = styled.div`
  padding: 3px 3px;
  border: 1px solid white;
  cursor: pointer;
`



export const Footer = () => {
  return (
    <Wrapper>
      <FooterContent>
        <FooterBody>
          <FooterHeaderSection>
            <LogoDiv>
              <Space size="1em" />
              <LogoText>VDesign Heritage</LogoText>
              <Desc align="left">
                Our team takes over everything, from an idea and concept development to realization. We believe in traditions and incorporate them within our innovations.
                {/* All our projects incorporate a unique artistic image and functional solutions. */}
              </Desc>

            </LogoDiv>
            <div>
              <GetInTouch>get in touch</GetInTouch>
              <Flex style={{
                gap: "10px",
                justifyContent: "right"
              }}>
                <Icons>
                  <GrLinkedinOption />
                </Icons>
                <Icons>
                  <FiFacebook />
                </Icons>
                <Icons>
                  <GrInstagram />
                </Icons>
                <Icons>
                  <GrTwitter />
                </Icons>
                <Icons>
                  <SiTiktok />
                </Icons>
              </Flex>

              <Space size="1.5em" />
              <Desc align="right">
                +234 806 410 9929
              </Desc>
              <Desc align="right">
                contact@vdesignheritage.com
              </Desc>
              <Desc align="right">
                Block D, Door 6, Corner Shop, Gwarimpa, FCT Abuja
              </Desc>
            </div>
          </FooterHeaderSection>
          <Space size="1.5em" />
          <p style={{ textAlign: 'center', letterSpacing: '1.5px' }}>VDESIGN HERITAGE (C) 2023 ALL RIGHTS RESERVED</p>

        </FooterBody>
      </FooterContent>

    </Wrapper>
  )
}