export const navMenu = [
  {
    title: "Home",
    link: "/"
  },
  {
    title: "Projects",
    link: "/projects"
  },
  {
    title: "Services",
    link: "/services"
  },
  {
    title: "About ",
    link: "/about"
  },
  {
    title: "Showcase",
    link: "/showcase"
  },
  {
    title: "Contact",
    link: "/contact"
  },
]