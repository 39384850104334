import styled from "styled-components";
import { THEME_COLORS } from "./theme";

export const Flex = styled.div`
  display: flex;
  align-items: center;
`

export const SpaceBetween = styled(Flex)`
  justify-content: space-between;
`

export const SpaceAround = styled(Flex)`
  justify-content: space-around;
`

export const Space = styled.div`
  margin: ${props => props.size ? props.size : "10px"};
`
export const Title = styled.h1`
  font-size: 70px;
  text-transform: uppercase;
  letter-spacing: 2px;
`
export const Description = styled.p`
  font-size: 20px;
  letter-spacing: 3px;
  font-weight: 500;
`
export const Button = styled.button`
  background-color: ${props => props.primary ? "teal" : props.bgc ? props.bgc : "transparent"};
  border: ${props => props.primary || props.outline ? "2px solid teal" : props.bgc ? "2px solid" + props.bgc : "none"};
  padding: 10px;
  /* border-radius: 5px; */
  font-size: 20px;
  /* font-weight: 700; */
  color: ${props => props.color || "black"};
  cursor: pointer;
`

export const AppBtn = styled.button`
  padding: 15px 25px;
  border: 1px solid #fff;
  background-color: ${props => props.transparent ? "transparent" : props.color ? props.color : "#fff"};
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 0.3s all ease-in;

  &:hover{
    background-color: ${props => props.transparent ? "#fff" : "#000"};
    /* border: 1px solid #000; */
    color: #000;
  }
`

export const AppBtnTwo = styled.button`
  padding: 15px 25px;
  border: 1px solid #000;
  background-color:  #fff;
  cursor: pointer;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 0.4s all ease-in;

  &:hover{
    background-color: #000;
    /* border: 1px solid #fff; */
    color: #fff;
  }
`

export const Subtitle = styled.h5`
  color: ${props => props.color ? props.color : THEME_COLORS.WHITE};
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 5px;
  margin-bottom: 2em;
  text-transform: uppercase;
  text-align: ${props => props.center ? "center" : props.right ? "right" : "left"};
  /* opacity: ${props => props.hidden ? 0 : 1}; */
`

export const AppTitle = styled.h3`
  color: ${props => props.color ? props.color : THEME_COLORS.WHITE};
  font-weight: 800;
  font-size: 3em;
  opacity: 0.9;
`

export const AppSubtitle = styled(Subtitle)`
  margin-bottom: 1em;
`


