import React, { useState } from 'react'
import Marquee from 'react-fast-marquee'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";

import { THEME_COLORS } from '../config/theme'
import { marqueeProjects } from '../data/marqueeProjects'
import { useMediaQuery } from '../customHooks/useMediaQuery';
import { device } from '../util/devices';

const Wrapper = styled.div`
  display: flex;
  margin-top: 5px;
`

const HeaderSection = styled.div`
  background-color: ${THEME_COLORS.BLACK};
  padding: ${props => props.isMobile ? "10px" : "20px"};
  font-size: 24px;
  font-weight: 900;
  color: ${THEME_COLORS.WHITE};
  letter-spacing: 1.5px;
`

export const MarqueeComponent = () => {
  const isMobile = useMediaQuery(device.mobile);

  return (
    <Wrapper>
      <HeaderSection isMobile={isMobile}>Showcase</HeaderSection>
      <Marquee
        pauseOnHover
      >
        {marqueeProjects.map((project, index) => (

          <MarqueeChild
            key={index}
            imgUrl={project.imgUrl}
            title={project.title}
            marqueeTitle={project.marqueeTitle}
            link={project.link}
          />
        ))}

      </Marquee>
    </Wrapper>
  )
}


const MarqueeChild = ({ imgUrl, title, marqueeTitle, link }) => {
  const navigate = useNavigate();

  const [active, setActive] = useState(false)

  const isMobile = useMediaQuery(device.mobile);

  const handleMouseEvent = () => {
    setActive(active => !active)
  }

  const handleOnclick = () => {
    if (!link) return
    navigate(`/projects/${link}`)
  }

  return (
    <Container onClick={handleOnclick} onMouseOver={handleMouseEvent} onMouseOut={handleMouseEvent}>
      <ImageDiv isMobile={isMobile}>
        <Image
          active={active}
          src={imgUrl}

        />
      </ImageDiv>
      <div>
        <Content>{marqueeTitle}</Content>
        <Content>{title}</Content>
      </div>

    </Container>
  )
}





const Container = styled.div`
  margin-right: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
  padding-right: 20px;
  border-radius: 70px;
  transition: 0.1s all ease-in;

  &:hover{
    color: ${THEME_COLORS.WHITE};
    background-color: ${THEME_COLORS.BLACK};

  }
`

const ImageDiv = styled.div`
  width: ${props => props.isMobile ? "50px" : "70px"};
  height: ${props => props.isMobile ? "50px" : "70px"};
  margin-right: 10px;
`

const Image = styled.img`
  width: inherit;
  height: inherit;
  border-radius: 100%;
  border: 3px solid ${props => props.active ? THEME_COLORS.WHITE : THEME_COLORS.BLACK};

`

const Content = styled.div``
