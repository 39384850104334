import React from 'react'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import { Flex, AppBtnTwo } from '../config/universalStyles'


const ProjectDetailWrap = styled(Flex)`
  flex-direction: ${props => props.isMobile ? "column" : props.reverse ? "row-reverse" : "row"};
  margin-bottom: 5em;
`

const ProjectImage = styled.div`
  width: ${props => props.isMobile ? "100%" : "55%"};
  height: ${props => props.isMobile ? "400px" : "500px"};
  /* height: 500px; */
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;
`

const ProjectTextWrap = styled.div`
  width: ${props => props.isMobile ? "100%" : "40%"};
  margin-left: ${props => props.reverse ? 0 : 'auto'};
  margin-right: ${props => props.reverse ? 'auto' : 0};
  text-align: ${props => props.isMobile ? "left" : props.reverse ? "right" : "left"};
  /* background-color: pink; */
`
const Year = styled.p`
  position: relative;
  font-size: 12px;
  line-height: 26px;
  font-weight: 300;
  margin-bottom: 20px;
  color: #111;

  &::after{
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    height: 1px;
    width: 85px;
    background-color: #111;
    right: ${props => props.reverse ? '-22%' : '100%'};
    margin-right: 15px;
  }
`

const ProjectTitle = styled.h3`
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: 800;
`
const ProjectDesc = styled.p`
  line-height: 33px;
  margin-bottom: 30px;
`


export const ProjectDetails = ({ reverse, imgUrl, year, title, desc, link, isMobile }) => {
  const navigate = useNavigate();

  const handleOnclick = () => {
    if (!link) return
    navigate(`/projects/${link}`)
  }
  return (
    <ProjectDetailWrap isMobile={isMobile} reverse={reverse}>
      <ProjectImage url={imgUrl} isMobile={isMobile} />
      <ProjectTextWrap isMobile={isMobile} reverse={reverse}>
        <Year hidden={isMobile} reverse={reverse}>{year}</Year>
        <ProjectTitle>{title}</ProjectTitle>
        <ProjectDesc>{desc}</ProjectDesc>
        <AppBtnTwo onClick={handleOnclick} color="black">View Project</AppBtnTwo>
      </ProjectTextWrap>

    </ProjectDetailWrap>
  )
}
