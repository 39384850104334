import { createBrowserRouter, Outlet } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { Home } from "../Pages/Home";
import { Contact } from "../Pages/Contact";
import { Projects } from "../Pages/Projects";
import { Services } from "../Pages/Services";
import { About } from "../Pages/About";
import { Blog } from "../Pages/Blog";
import { Project } from "../Pages/Project";

const Layout = () => {
  return (
    <div style={
      {
        position: "relative",
      }
    }>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  )
}


export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/projects",
        element: <Projects />
      },
      {
        path: "/projects/:id",
        element: <Project />
      },
      {
        path: "/services",
        element: <Services />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/showcase",
        element: <Blog />
      },
    ]
  },
]);