import React from 'react'
import styled from 'styled-components'
import { AppBtn, Space, Flex, AppBtnTwo, Subtitle, AppTitle, AppSubtitle, SpaceBetween } from '../config/universalStyles'
import { THEME_COLORS } from '../config/theme'
import { SectionHead } from '../components/SectionHead'
import { useMediaQuery } from '../customHooks/useMediaQuery'
import { device } from '../util/devices'



const Wrapper = styled.div``

const SectionOne = styled.section`
  background-image: url("https://prague.foxthemes.me/wp-content/uploads/2016/05/9b437744429717.5812370040b1c.jpg");
  height: 70vh;
  top: 0;
  background-repeat: no-repeat;
`
const SectionOneContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  background-color: rgba(0,0,0,0.3);
`

const Title = styled.h3`
  color: #fff;
  font-weight: 700;
  font-size: ${props => props.isMobile ? "3em" : "4em"};
  opacity: 0.8;
  text-align: center;
`
const SectionTwo = styled.div`
  width: 80%;
  margin: 10em auto;
`

const Desc = styled.p`
  font-size: 18px;
  line-height: 2em;
  text-align: ${props => props.align};
  color: ${THEME_COLORS.FONT_COLOR};
  opacity: 0.7;
`

const SectionWrapper = styled(SpaceBetween)`
  flex-direction: ${props => props.isMobile ? "column" : "row"};
`

const FlexedDiv = styled.div`
  width: ${props => props.isMobile ? "100%" : "45%"};
`

const ContactInfoWrapper = styled(SpaceBetween)`
  /* width: 100%; */
  /* flex-direction: column; */
  flex-direction: ${props => props.isMobile ? "column" : "row"};
`


export const Contact = () => {
  const isMobile = useMediaQuery(device.mobile);
  const isTabletL = useMediaQuery(device.tabletL);
  return (
    <Wrapper>
      <SectionOne>
        <SectionOneContent>
          <Subtitle>Contact Us</Subtitle>
          <Title isMobile={isMobile}>Let's Start Our Journey</Title>
        </SectionOneContent>
      </SectionOne>
      <SectionTwo>
        <SectionHead
          subTitle="Contact"
          title="Let's start new project."
        />

        <SectionWrapper isMobile={isMobile}>
          <FlexedDiv isMobile={isMobile}>
            <Desc align="left">
              Now, as you were able to get a picture of who we are, it is up to you to contact us and lay the foundation for a new and successful business relationship.
            </Desc>

            <ContactInfoWrapper isMobile={isMobile}>
              <ContactCard
                title="PHONE"
                contactOne="+45 (0)4 79 25 37 98"
                contactTwo="+45 (0)4 79 25 37 98"
                isMobile={isMobile}
              />
              <ContactCard
                title="EMAIL"
                contactOne="hello@vdesignheritage.com"
                contactTwo="support@vdesignheritage.com"
                isMobile={isMobile}
              />
              <ContactCard
                title="LINKEDIN"
                contactOne="hello@vdesignheritage.com"
                contactTwo="support@vdesignheritage.com"
                isMobile={isMobile}
              />
            </ContactInfoWrapper>
          </FlexedDiv>

          <FlexedDiv isMobile={isMobile}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.6034504892064!2d7.4075220759246445!3d9.099840687874206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e7560068c1843%3A0xa9c8c4c3e716fdb3!2sVdesign%20Heritage!5e0!3m2!1sen!2sng!4v1681555278582!5m2!1sen!2sng"
              height="400"
              style={{ border: 0, width: isMobile ? "100%" : "550px", height: "400px", marginTop: isMobile ? "2em" : 0 }}
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </FlexedDiv>

        </SectionWrapper>


      </SectionTwo>

    </Wrapper>
  )
}

const ContactCardWrapper = styled.div`
  width: ${props => props.isMobile ? "100%" : "28%"};
  /* background-color: pink; */

  
   &::before{
    content: '';
    width: 70px;
    border: 0.8px solid black;
    display: block;
    margin-top: 2em;
    margin-bottom: 2em;
  }
`

const ContactCardText = styled.p`
  font-size: 14px;
  color: ${props => props.color ? props.color : "#000"};
`

const ContactCard = ({ title, contactOne, contactTwo, isMobile }) => {
  return (
    <ContactCardWrapper isMobile={isMobile}>
      <ContactCardText> {title} </ContactCardText>
      <Space size="2em" />
      <ContactCardText color="rgba(0,0,0,0.7)">{contactOne}</ContactCardText>
      <Space size="1em" />
      <ContactCardText color="rgba(0,0,0,0.7)">{contactTwo} </ContactCardText>

    </ContactCardWrapper>
  )
}
